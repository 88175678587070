@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.book-container {
  perspective: 1000px; /* Adds depth to the 3D effect */
  width: 800px; /* Adjust based on your flipbook size */
  height: 600px;
  overflow: hidden; /* Hide overflow during flip transitions */
  margin: auto; /* Center the flipbook on the page */
  margin-top: 40px;
  position: relative;
}

.flipbook {
  width: 800px;
  height: 600px;
  margin-top: 20px; /* Additional space can be adjusted if needed */
  position: relative;
  margin: 2rem auto; /* Centering the book */
  transform-style: preserve-3d;
  transform-origin: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.page {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Distributes space between image and text */
  transform: translateZ(0); /* GPU acceleration for smoother transitions */
  background: white; /* Page background */
  padding: 1rem;
  box-shadow: inset 0 0 10px #00000050; /* Inner shadow for a slight depth effect */
}

.page img {
  max-height: 70%; /* Limits image size */
  width: auto; /* Maintain aspect ratio */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Shadow for the image for depth */
}

.page p {
  text-align: justify;
  color: #333;
  font-size: 18px;
  padding: 8px;
  background: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
